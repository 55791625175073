import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  stringToDate(dateString: string): Date | null {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    let result: any;
    if (regex.test(dateString)) {
      const [year, month, day] = dateString.split('-').map(Number);
      result = new Date(year, month - 1, day);
      if (result.getFullYear() !== year || result.getMonth() !== month - 1 || result.getDate() !== day) {
        result = 'Fecha inválida.';
      }
    } else {
      result = 'Formato de fecha inválido. Se requiere \'YYYY-MM-DD\'.';
    }
    return result;
  }


  buildDate(date: Date = new Date(), years: number = 0, months: number = 0, days: number = 0, time: string = '00:00:00') {
    const d = new Date(date);
    const y = d.getFullYear();
    const m = d.getMonth();
    const dd = d.getDate();
    const h = d.getHours();
    const mm = d.getMinutes();
    const s = d.getSeconds();
    d.setFullYear(y + years);
    d.setMonth(m + months);
    d.setDate(dd + days);
    if (time !== '00:00:00') {
      const [horas, minutos, segundos] = time.split(':').map(Number);
      d.setHours(h + horas);
      d.setMinutes(mm + minutos);
      d.setSeconds(s + segundos);
    }
    return d;
  }

  buildNaturalRange(end: Date = new Date(), range: string) {
    const date = new Date(end);
    const rangeMod = {
      date_end: new Date(end),
      date_ini: new Date(end)
    };
    const d = date.getDate();
    const m = date.getMonth();
    const y = date.getFullYear();
    switch (range) {
      case 'week':
        const dayOfWeek = rangeMod.date_ini.getDay();
        rangeMod.date_ini.setDate(d - dayOfWeek);
        const dayOfWeekEnd = rangeMod.date_end.getDay();
        rangeMod.date_end.setDate(d + (6 - dayOfWeekEnd));
        break;
      case 'month':
        rangeMod.date_ini.setDate(1);
        rangeMod.date_end.setMonth(m + 1, 0);
        break;
      case '3months':
        rangeMod.date_ini.setMonth(m - 2, 1);
        rangeMod.date_end.setMonth(m + 1, 0);
        break;
      case '6months':
        rangeMod.date_ini.setMonth(m - 5, 1);
        rangeMod.date_end.setMonth(m + 1, 0);
        break;
      case '12months':
        rangeMod.date_ini.setMonth(m - 11, 1);
        rangeMod.date_end.setMonth(m + 1, 0);
        break;
      case 'year':
        rangeMod.date_ini.setFullYear(y, 0, 1);
        rangeMod.date_end.setFullYear(y + 1, 0, 0);
        break;
    }
    rangeMod.date_ini.setHours(0, 0, 0, 0);
    rangeMod.date_end.setHours(23, 59, 59, 999);
    console.log ('NaturalRange ' + range) ;
    return {
      date_end: rangeMod.date_end,
      date_ini: rangeMod.date_ini
    };
  }

  buildDynamicRange(end: Date = new Date(), range: string) {
    const date = new Date(end);
    const rangeMod = {
      date_end: new Date(end),
      date_ini: new Date(end)
    };
    const d = date.getDate();
    const m = date.getMonth();
    const y = date.getFullYear();
    switch (range) {
      case 'day':
        rangeMod.date_ini.setDate(d - 1);
        break;
      case 'week':
        rangeMod.date_ini.setDate(d - 7);
        break;
      case 'month':
        rangeMod.date_ini.setMonth(m - 1);
        break;
      case '3months':
        rangeMod.date_ini.setMonth(m - 3);
        break;
      case '6months':
        rangeMod.date_ini.setMonth(m - 6);
        break;
      case 'year':
        rangeMod.date_ini.setFullYear(y - 1);
        break;
    }
    rangeMod.date_ini.setHours(0, 0, 0, 0);
    rangeMod.date_end.setHours(23, 59, 59, 999);
    console.log ('DinamicRange ' + range) ;
    return {
      date_end: rangeMod.date_end,
      date_ini: rangeMod.date_ini
    };
  }


  buildInOutRange(ini: Date = new Date(), end: Date = new Date()) {
    const rangeMod = {
      date_end: new Date(end),
      date_ini: new Date(ini)
    };
    rangeMod.date_ini.setHours(0, 0, 0, 0);
    rangeMod.date_end.setHours(23, 59, 59, 999);
    return {
      date_end: rangeMod.date_end,
      date_ini: rangeMod.date_ini
    };
  }

  naturalStringRange(d: Date = new Date(), range: string) {
    const dates = this.buildNaturalRange(d, range);
    return {
      date_end: this.toISO(dates.date_end),
      date_ini: this.toISO(dates.date_ini)
    };
  }

  dynamicStringRange(d: Date = new Date(), range: string) {
    const dates = this.buildDynamicRange(d, range);
    return {
      date_end: this.toISO(dates.date_end),
      date_ini: this.toISO(dates.date_ini)
    };
  }

  toISO(d: Date) {
    return d.toISOString();
  }

}
