import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {RolesService} from '@services';


@Injectable({
  providedIn: 'root',
})
export class UsersGuard {

  constructor(private router: Router) {
  }

  canActivate() {
    if (!RolesService.isManager) {
      return true;
    }
    this.router.navigate(['/horus/panel-de-administracion']).then(() => {
      console.log('Not Allowed');
    });
    return false;
  }
}
