import {Routes} from '@angular/router';
import {
  AgentsTelesConfigGuard, AuthConfigGuard,
  NotAdminsConfigGuard,
  PartnersConfigGuard,
  ReportsConfigGuard,
  TeamsConfigGuard,
  LowerPartnerAdminConfigGuard,
  UsersConfigGuard
} from '@guards';
import {UserStatsDashboardComponent} from './app/protected/stats/user-stats-dashboard/user-stats-dashboard.component';
import { UsersGuard } from './app/shared/guards/users.guard';
import { ManagersGuard } from './app/shared/guards/managers.guard';


export const routes: Routes = [{
  path: '',
  children: [
    {path: '', redirectTo: 'acceso/inicio-sesion', pathMatch: 'full'},
    {
      path: 'acceso/:access', loadComponent: () => import('src/app/access/access.component').then(m => m.AccessComponent)
    },
    {
      path: 'info/:info', loadComponent: () => import('src/app/info/info.component').then(m => m.InfoComponent)
    },
    {
      path: 'valoracion/:assessment',
      loadComponent: () => import('src/app/protected/appraiser/print-report/print-report.component').then(m => m.PrintReportComponent)
    },
    {
      path: 'viewers',
      canActivate: [AuthConfigGuard],
      loadComponent: () =>
        import('src/app/shared/components/viewers/viewers.component').then(m => m.ViewersComponent)
    },
    {
      path: 'horus',
      canActivate: [AuthConfigGuard],
      loadComponent: () => import('src/app/protected/protected.component').then(m => m.ProtectedComponent),
      children: [
        {path: '', pathMatch: 'full', redirectTo: 'panel-de-control'},
        {
          path: 'panel-de-control',
          canActivate: [UsersGuard],
          runGuardsAndResolvers: 'always',
          loadComponent: () =>
            import('src/app/protected/dashboard/dashboard.component').then(m => m.DashboardComponent)
        },
        {
          path: 'panel-de-administracion',
          canActivate: [ManagersGuard],
          runGuardsAndResolvers: 'always',
          loadComponent: () =>
            import('src/app/protected/dashboard-admin/dashboard-admin.component').then(m => m.DashboardAdminComponent)
        },
        {
          path: 'vista-aguila',
          runGuardsAndResolvers: 'always',
          loadComponent: () =>
            import('src/app/protected/eagle/eagle.component').then(m => m.EagleComponent)
        },
        {
          path: 'contactos/archivados',
          canActivate: [NotAdminsConfigGuard],
          runGuardsAndResolvers: 'always',
          loadComponent: () =>
            import('src/app/protected/leads/leads.component').then(m => m.LeadsComponent)
        },
        {
          path: 'contactos',
          canActivate: [NotAdminsConfigGuard],
          runGuardsAndResolvers: 'always',
          loadComponent: () =>
            import('src/app/protected/leads/leads.component').then(m => m.LeadsComponent)
        },
        {
          path: 'contacto/:id',
          canActivate: [NotAdminsConfigGuard],
          runGuardsAndResolvers: 'always',
          loadComponent: () =>
            import('src/app/protected/leads/lead-manager/lead-manager.component').then(m => m.LeadManagerComponent),
          outlet: 'popup'
        },
        {
          path: 'colaborador/:id',
          canActivate: [UsersConfigGuard],
          runGuardsAndResolvers: 'always',
          loadComponent: () =>
            import('src/app/protected/leads/lead-manager/lead-manager.component').then(m => m.LeadManagerComponent),
          outlet: 'popup'
        },
        {
          path: 'expedientes/archivados',
          runGuardsAndResolvers: 'always',
          loadComponent: () =>
            import('src/app/protected/re-files/re-files.component').then(m => m.ReFilesComponent)
        },
        {
          path: 'expedientes',
          runGuardsAndResolvers: 'always',
          loadComponent: () =>
            import('src/app/protected/re-files/re-files.component').then(m => m.ReFilesComponent),
          children: [
            {
              canActivate: [LowerPartnerAdminConfigGuard],
              path: 'expediente/nuevo',
              loadComponent: () => import('src/app/protected/re-files/new-re-file/new-re-file.component').then(m => m.NewReFileComponent)
            }
          ]
        },
        {
          path: 'expedientes/expediente/:id',
          runGuardsAndResolvers: 'always',
          loadComponent: () => import('src/app/protected/re-files/re-file/re-file.component')
            .then(m => m.ReFileComponent)
        },
        {
          path: 'dossier-edit/:id',
          runGuardsAndResolvers: 'always',
          loadComponent: () => import('src/app/protected/re-files/re-file-manager/re-file-manager.component')
            .then(m => m.ReFileManagerComponent),
          outlet: 'popup'
        },
        {
          path: 'usuarios/:id',
              loadComponent: () => import('src/app/protected/config/users/user-manager/user-manager.component')
                .then(m => m.UserManagerComponent),
              outlet: 'popup'
        },
        {
          path: 'socios/:id', canActivate: [TeamsConfigGuard],
          loadComponent: () =>
            import('src/app/protected/config/partners/partner-manager/partner-manager.component')
              .then(m => m.PartnerManagerComponent),
          outlet: 'popup'
        },
        {
          path: 'equipos/:id', canActivate: [UsersConfigGuard],
          loadComponent: () => import('src/app/protected/config/teams/team-manager/team-manager.component')
            .then(m => m.TeamManagerComponent),
          outlet: 'popup'
        },
        {
          path: 'alarmas',
          canActivate: [NotAdminsConfigGuard],
          runGuardsAndResolvers: 'always',
          loadComponent: () =>
            import('src/app/protected/alarms/alarms.component').then(m => m.AlarmsComponent),
          children: [
            {
              path: 'alarma/:id',
              loadComponent: () =>
                import('src/app/protected/alarms/alarm-manager/alarm-manager.component').then(m => m.AlarmManagerComponent)
            },
            {
              path: 'alarma/:id/:options',
              loadComponent: () =>
                import('src/app/protected/alarms/alarm-manager/alarm-manager.component').then(m => m.AlarmManagerComponent)
            },
          ]
        },
        {
          path: 'reportes',
          loadComponent: () => import('src/app/protected/stats/stats.component')
            .then(m => m.StatsDashboardComponent),
        },
        {
          path: 'reportes/usuario/:id',
          canActivate: [UsersConfigGuard],
          loadComponent: () => import('src/app/protected/stats/stats.component')
            .then(m => m.StatsDashboardComponent),
        },
        {
          path: 'retos',
          runGuardsAndResolvers: 'always',
          canActivate: [UsersConfigGuard],
          loadComponent: () => import('src/app/protected/goals/goals.component')
            .then(m => m.GoalsComponent)
        },
        {
          path: 'reportes__',
          loadComponent: () =>
            import('src/app/protected/config/reports/reports.component').then(m => m.ReportsComponent),
          canActivate: [ReportsConfigGuard],
          runGuardsAndResolvers: 'always',
          children: [
            {
              path: 'reportes-a-medida/:type',
              runGuardsAndResolvers: 'always',
              loadComponent: () =>
                import('src/app/protected/config/reports/report-builder/report-builder.component').then(m => m.ReportBuilderComponent)
            },
            {
              path: 'reportes-a-medida',
              runGuardsAndResolvers: 'always',
              loadComponent: () =>
                import('src/app/protected/config/reports/report-builder/report-builder.component').then(m => m.ReportBuilderComponent)
            },
            {
              path: 'reportes-de-usuario',
              loadComponent: () => import('src/app/protected/config/users/users.component').then(m => m.UsersComponent)
            },
            {
              path: 'reportes-de-usuario/:id',
              loadComponent: () =>
                import('src/app/protected/config/reports/report-user/report-user.component').then(m => m.ReportUserComponent)
            }
          ]
        },
        {
          path: 'configuracion', loadComponent: () =>
            import('src/app/protected/config/config.component').then(m => m.ConfigComponent),
          children: [
            {
              path: 'gestion-de-socios/archivados',
              canActivate: [PartnersConfigGuard],
              runGuardsAndResolvers: 'always',
              loadComponent: () => import('src/app/protected/config/partners/partners.component').then(m => m.PartnersComponent),
            },
            {
              path: 'gestion-de-socios',
              canActivate: [PartnersConfigGuard],
              runGuardsAndResolvers: 'always',
              loadComponent: () => import('src/app/protected/config/partners/partners.component').then(m => m.PartnersComponent),
            },
            {
              path: 'gestion-de-retos/archivados',
              canActivate: [TeamsConfigGuard],
              runGuardsAndResolvers: 'always',
              loadComponent: () => import('src/app/protected/config/goals/goals.component').then(m => m.GoalsComponent),
              children: [
                {
                  path: 'reto/:id',
                  loadComponent: () => import('src/app/protected/config/goals/goal-manager/goal-manager.component')
                    .then(m => m.GoalManagerComponent)
                }
              ]
            },
            {
              path: 'gestion-de-retos',
              canActivate: [TeamsConfigGuard],
              runGuardsAndResolvers: 'always',
              loadComponent: () => import('src/app/protected/config/goals/goals.component').then(m => m.GoalsComponent),
              children: [
                {
                  path: 'reto/:id',
                  loadComponent: () => import('src/app/protected/config/goals/goal-manager/goal-manager.component')
                    .then(m => m.GoalManagerComponent)
                }
              ]
            },
            {
              path: 'gestion-de-equipos/archivados',
              canActivate: [TeamsConfigGuard],
              loadComponent: () => import('src/app/protected/config/teams/teams.component').then(m => m.TeamsComponent),
            },
            {
              path: 'gestion-de-equipos',
              canActivate: [TeamsConfigGuard],
              loadComponent: () => import('src/app/protected/config/teams/teams.component').then(m => m.TeamsComponent),
            },
            {
              path: 'gestion-de-usuarios/archivados',
              canActivate: [UsersConfigGuard],
              runGuardsAndResolvers: 'always',
              loadComponent: () => import('src/app/protected/config/users/users.component').then(m => m.UsersComponent),
            },
            {
              path: 'gestion-de-usuarios',
              canActivate: [UsersConfigGuard],
              runGuardsAndResolvers: 'always',
              loadComponent: () => import('src/app/protected/config/users/users.component').then(m => m.UsersComponent),
            },
            {
              path: 'gestion-de-colaboradores/archivados',
              canActivate: [UsersConfigGuard],
              runGuardsAndResolvers: 'always',
              loadComponent: () => import('src/app/protected/leads/leads.component').then(m => m.LeadsComponent),
            },
            {
              path: 'gestion-de-colaboradores',
              canActivate: [UsersConfigGuard],
              runGuardsAndResolvers: 'always',
              loadComponent: () => import('src/app/protected/leads/leads.component').then(m => m.LeadsComponent),
            },
            {
              path: 'mis-etiquetas/archivadas',
              canActivate: [AgentsTelesConfigGuard],
              loadComponent: () => import('src/app/protected/config/tags/tags.component').then(m => m.TagsComponent),
              children: [
                {
                  path: 'etiqueta/:id',
                  loadComponent: () => import('src/app/protected/config/tags/tag-manager/tag-manager.component')
                    .then(m => m.TagManagerComponent)
                },
              ]
            },
            {
              path: 'mis-etiquetas',
              canActivate: [AgentsTelesConfigGuard],
              loadComponent: () => import('src/app/protected/config/tags/tags.component').then(m => m.TagsComponent),
              children: [
                {
                  path: 'etiqueta/:id',
                  loadComponent: () => import('src/app/protected/config/tags/tag-manager/tag-manager.component')
                    .then(m => m.TagManagerComponent)
                },
              ]
            },
            {
              path: 'planes-de-comunicacion',
              canActivate: [TeamsConfigGuard],
              runGuardsAndResolvers: 'always',
              loadComponent: () => import('src/app/protected/config/communications/communications.component')
                .then(m => m.CommunicationsComponent),
              children: [
                {
                  path: 'plan/:id',
                  loadComponent: () => import('src/app/protected/config/communications/communication-manager/communication-manager.component').then(m => m.CommunicationManagerComponent)
                }
              ]
            },
            {
              path: 'gestion-de-acciones',
              canActivate: [TeamsConfigGuard],
              runGuardsAndResolvers: 'always',
              loadComponent: () => import('src/app/protected/config/actions/actions.component').then(m => m.ActionsComponent),
              children: [
                {
                  path: 'accion/:id',
                  loadComponent: () => import('src/app/protected/config/actions/action-manager/action-manager.component')
                    .then(m => m.ActionManagerComponent)
                }
              ]
            },
            {
              path: 'gestion-de-plantillas',
              canActivate: [TeamsConfigGuard],
              runGuardsAndResolvers: 'always',
              loadComponent: () => import('src/app/protected/config/templates/templates.component').then(m => m.TemplatesComponent),
              children: [
                {
                  path: 'plantilla/:id',
                  loadComponent: () => import('src/app/protected/config/templates/template-manager/template-manager.component')
                    .then(m => m.TemplateManagerComponent)
                }
              ]
            },
            {
              path: 'mis-plantillas',
              canActivate: [AgentsTelesConfigGuard],
              runGuardsAndResolvers: 'always',
              loadComponent: () => import('src/app/protected/config/templates/templates.component').then(m => m.TemplatesComponent),
              children: [
                {
                  path: 'plantilla/:id',
                  loadComponent: () =>
                    import('src/app/protected/config/templates/template-manager/template-manager.component')
                      .then(m => m.TemplateManagerComponent)
                }
              ]
            }
          ]
        },
        {
          path: 'valorador', canActivate: [AgentsTelesConfigGuard],
          loadComponent: () =>
            import('src/app/protected/appraiser/appraiser.component').then(m => m.AppraiserComponent)
        },
        {
          path: '404',
          loadComponent: () =>
            import('src/app/protected/not-found/not-found.component').then(m => m.NotFoundComponent)
        }
      ]
    }
  ]
}];
